import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { CaseState } from "@vp/data-access/case";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { UserApiService, UserFilter } from "@vp/data-access/users";
import { getDataValueFromDotPath, SchemaFieldType } from "@vp/formly/json-schema";
import { Organization, Role, Tag, User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from "rxjs";

@Component({
  selector: "vp-formly-users-by-tags-and-role",
  templateUrl: "./formly-users-by-tags-and-role.component.html",
  styleUrl: "./formly-users-by-tags-and-role.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserApiService]
})
export class FilterUsersByTagsAndRoleComponent
  extends SchemaFieldType
  implements OnDestroy, AfterViewInit, OnInit
{
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  private readonly _selectedTags$ = new BehaviorSubject<Map<string, Tag>>(new Map<string, Tag>());
  private readonly _selectedRole$ = new BehaviorSubject<Role | null>(null);
  private readonly _usersToDisplay$ = new BehaviorSubject<User[]>([]);
  private readonly _selectedUser$ = new BehaviorSubject<string>("");
  private readonly _destroyed$ = new Subject<void>();

  selectedRole$ = this._selectedRole$.asObservable();
  usersToDisplay$ = this._usersToDisplay$.asObservable();
  selectedTags$ = this._selectedTags$.asObservable();
  selectedUser$ = this._selectedUser$.asObservable();

  filteringTagTypes$: Observable<string[]> = this.organization$.pipe(
    takeUntil(this._destroyed$),
    map((organization: Organization) => {
      const userTagTypes = this.props.tagTypes;
      return organization.tagTypes
        .filter(t => userTagTypes.includes(t.friendlyId))
        .map(x => x.friendlyId);
    })
  );

  OnBehalfRoles$ = this.featureService.configurationLists$("caseCreate").pipe(
    map(configurationList => {
      return configurationList["onBehalfRoles"];
    })
  );

  rolesAllowedToUseOnBehalfFeature$ = this.featureService.configurationLists$("caseCreate").pipe(
    map(configurationList => {
      const authenticatedUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
      const currentRole = authenticatedUser?.roles.find(
        x => x.roleId === authenticatedUser.selectedRoleId
      );
      const rolesAllowed = configurationList["rolesAllowedToUseOnBehalfFeature"];
      return rolesAllowed.includes(currentRole?.friendlyId || "");
    })
  );

  filteringRoles$: Observable<Role[]> = this.organization$.pipe(
    takeUntil(this._destroyed$),
    withLatestFrom(this.OnBehalfRoles$),
    map(([organization, onBehalfRoles]: [Organization, string[]]) => {
      return organization.roles.filter(t => onBehalfRoles.includes(t.friendlyId));
    })
  );

  tagsMatching$: Observable<string[]> = this.filteringTagTypes$.pipe(
    takeUntil(this._destroyed$),
    withLatestFrom(this.tags$),
    map(([filteringTagTypes, tags]: [string[], Tag[]]) => {
      const logguedUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
      const userTags = logguedUser?.assignedTags;
      return tags
        .filter(
          t => userTags?.includes(t.tagId) && filteringTagTypes.find(x => x === t.tagTypeFriendlyId)
        )
        .map(x => x.tagId);
    })
  );

  ngOnInit(): void {
    if (this.props.module === "wizard") {
      combineLatest([this.filteringRoles$, this.tagsMatching$])
        .pipe(
          map(([filteringRoles, tagsMatching]) => {
            return {
              filters: [`roles.roleId=${filteringRoles[0].roleId}`],
              tags: tagsMatching
            } as UserFilter;
          }),
          take(1),
          switchMap((filter: UserFilter) => this.userApiService.getUsers(filter, false)),
          takeUntil(this._destroyed$)
        )
        .subscribe(models => this._usersToDisplay$.next(models.filter(x => x.active)));
    }
  }

  updateSelection(nxtValue: string) {
    this.usersToDisplay$.pipe(filterNullMap(), take(1)).subscribe((userList: User[]) => {
      const user = userList.find(user => user.userId === nxtValue);
      if (user) {
        this._selectedUser$.next(nxtValue);
        this.toggleSelection(user);
      } else {
        this._selectedUser$.next("");
        this.toggleSelection(null);
      }
    });
  }

  displayedName(x: User) {
    const salutation = x.profile.salutation ? x.profile.salutation : this.props.defaultSalutation;
    const fullName = x.profile.lastName
      ? `${x.profile.lastName}, ${x.profile.firstName}`
      : x.profile.firstName;
    return `${salutation} ${fullName}`;
  }

  ngAfterViewInit(): void {
    if (this.props.module === "wizard") {
      const caseData = this.store.selectSnapshot(CaseState.current);
      if (caseData?.recordData) {
        const drpOnBehalf = getDataValueFromDotPath(caseData?.recordData, "homeDoctor.drpOnBehalf");
        if (drpOnBehalf) {
          this.updateSelection(drpOnBehalf);
        }
      }
    }
  }

  constructor(
    private readonly userApiService: UserApiService,
    private readonly store: Store,
    private readonly featureService: FeatureService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggleSelection(user: User | null) {
    const authenticatedUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
    this._selectedUser$.next(user ? user.userId : "");
    this.setFieldValue("drpOnBehalf", user ? user.userId : null);
    this.setFieldValue("txtFirstName", user && user.profile ? user.profile.firstName : null);
    this.setFieldValue("txtLastName", user && user.profile ? user.profile.lastName : null);
    this.setFieldValue("txtPhone", user && user.profile ? user.profile.primaryPhone : null);
    this.setFieldValue(
      "txtCreatorOnBehalfEmail",
      authenticatedUser ? authenticatedUser.email : null
    );
    this.setFieldValue("txtEmail", user && user.profile ? user.email : null);
  }

  setFieldValue(fieldName: string, value: string | null) {
    const fieldGroup = this.field.parent?.fieldGroup?.find(f => f.key === fieldName);
    if (fieldGroup && value) {
      fieldGroup.formControl?.setValue(value);
      fieldGroup.formControl?.disable();
    } else {
      fieldGroup?.formControl?.setValue(value);
      fieldGroup?.formControl?.enable();
    }
  }
}
